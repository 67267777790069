<template>
  <div class="container">
    <div class="box-title" style="display:flex;justify-content: space-between">
      <h4>
        <i class="fal fa-user-chart"></i>
        ຂໍ້ມູນລາຍການແນະນຳລູກຄ້າລົງທະບຽນໃໝ່
        <span class="countTotal"></span>
      </h4>
      <vs-button success style="margin-bottom: 8px;" @click="downloadExcel" :loading="loading">
        download excel
      </vs-button>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  @keyup.enter="FilterData()"
                  v-model="searchItem"
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
              >
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <label>ເລືອກປະເພດ</label>
              <model-list-select
                  :list="typeSelected"
                  placeholder="ເລືອກປະເພດ"
                  option-text="name"
                  v-model="type"
                  option-value="id"
                  selectedItem="name">
              </model-list-select>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                type="date"
                :clearable="false"
                name="start_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker

                type="date"
                :clearable="false"
                name="end_date"
                v-model="end_date"
                :default-value="new Date()"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່ຜູ້ແນະນຳ</vs-th>
            <vs-th>ເບີໂທຜູ້ແນະນຳ</vs-th>
            <vs-th>ຊື່ຜູ້ຖືກແນະນຳ</vs-th>
            <vs-th>ເບີໂທຜູ້ຖືກແນະນຳ</vs-th>
            <vs-th>ຈຳນວນພ້ອຍ</vs-th>
            <vs-th>ວັນທີ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in listReferral" :key="index">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>
              <div v-if="item && item.inviter">
                {{ item.inviter.name }}
              </div>
            </vs-td>
            <vs-td>
              <div v-if="item && item.inviter">
                {{ item.inviter.user_tel }}
              </div>
            </vs-td>
            <vs-td>
              <div v-if="item && item.invitee">
                {{ item.invitee.name }}
              </div>
            </vs-td>
            <vs-td>
              <div v-if="item && item.invitee">
                {{ item.invitee.user_tel }}
              </div>
            </vs-td>
            <vs-td>
              <div style="display: flex">
                <span style="margin-left: 4px">
                     <i style="color:#ffbf10 " class="fas fa-usd-circle"></i>
                    {{ $helpers.numberFormatter(item.transaction.amount) }}
                  </span>
                <vs-tooltip style="margin-left: 4px;">
                  <i class="fal fa-info-square"></i>
                  <template #tooltip>
                    {{ item.transaction.description }}
                  </template>
                </vs-tooltip>
              </div>

            </vs-td>
            <vs-td>
              {{ formatDate(item.created_at) }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';
import moment from "moment";
import pagination from "@/Plugins/Paginations/CusorPagination";
import Loading from "@views/Loading";
import * as helper from "@/Helpers/GetValue";


export default {
  name: "Index",
  components: {
    DatePicker,
    ModelListSelect,
    pagination,
    Loading
  },
  data() {
    return {
      start_date: new Date(),
      end_date: new Date(),
      typeSelected: [
        {
          id: "employee",
          name: "ພະນັກງານ"
        },
        {
          id: "customer",
          name: "ລູກຄ້າ"
        }
      ],
      type: "",
      listReferral: [],
      isLoading: false,
      searchItem: "",
      // Pagination
      page: 1,
      max: 10,
      offset: 10,
      count: 0,
      pagination: {},
      per_page: 10,
      limit_page: 10,
      loading: false,
    }
  },
  watch: {
    'type': function () {
      this.FetchData();
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
  },
  methods: {
    downloadExcel() {
      const loading = this.BtnLoading();
      this.loading = true;
      const item = {
        start_date: moment(this.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.end_date).format('YYYY-MM-DD'),
        print: "yes"
      }
      this.$axios
          .post(`print/list/referral`, item, {
            responseType: "blob",
          })
          .then((res) => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            if (this.start_date === this.end_date) {
              fileLink.setAttribute('download', `ລາຍການແນະນຳລົງທະບຽນລູກຄ້າໃໝ່ວັນທີ ` + moment(item.start_date).format('DD-MM-YYYY') + '.xlsx');
            } else {
              fileLink.setAttribute('download', `ລາຍການແນະນຳລົງທະບຽນລູກຄ້າໃໝ່ວັນທີ ` + moment(item.start_date).format('DD-MM-YYYY') + 'ຫາ' + moment(item.end_date).format('DD-MM-YYYY') + '.xlsx');
            }
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.loading = false;
            loading.close()
          });
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm")
    },
    fixedNumber(number) {
      if (number) {
        return Number.parseFloat(number).toFixed(2);
      }
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItem().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.listReferral = res.data.data.data;
            this.pagination = res.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    fetchItem(options = {}) {
      const {current_page, per_page} = options;
      return this.$axios.get('/list/referral', {
        params: {
          type: this.type,
          start_date: moment(this.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.end_date).format('YYYY-MM-DD'),
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.FetchData();
  }
}
</script>

<style scoped lang="scss">
</style>
